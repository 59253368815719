<template>
<div class="page-wrap">
  <NavBar  navBarTitle="联系我们" back/>
  <div class="page-body">
    <div class="section-wrap center">
      <icon-font name="service" size="12vw" color="#999"></icon-font>
      <h2>购买销售</h2>
      <p class="section-des">周一 至 周日：24小时（北京时间）</p>
      <div class="section-des">
        <p v-for="qq in trade_qq_numbers" :key="qq">
          <a :href="`http://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes`" target="_blank" class="qq-icon center">
            <img style="height: 5vw" border="0" :src="require('@/assets/images/qq_icon.png')"/>
            &nbsp;点击咨询
          </a>
        </p>
      </div>
    </div>
    <div class="section-wrap center">
      <icon-font name="call" size="12vw" color="#999"></icon-font>
      <h2>热线服务</h2>
      <p class="section-des">周一 至 周日：24小时（北京时间）</p>
      <div class="section-des">
        <p>固话：<a href="tel:020-84083341">020-84083341</a></p>  
        <p v-for="phone in phone_numbers" :key="phone">
          手机：<a :href="`tel:${phone}`">+86&nbsp;{{ phone }}</a></p>
      </div>
    </div>
    <div class="section-wrap center">
      <icon-font name="mail" size="12vw" color="#999"></icon-font>
      <h2>邮箱服务</h2>
      <p class="section-des">周一 至 周日：24小时（北京时间）</p>
      <div class="section-des">
        <p>1004658237@qq.com</p>
      </div>
    </div>
    <div class="section-wrap center">
      <icon-font name="help-circle" size="12vw" color="#999"></icon-font>
      <h2>技术支持</h2>
      <p class="section-des">周一 至 周五：8:30 - 17:30（北京时间）</p>
      <div class="section-des">
        <p v-for="qq in service_qq_numbers" :key="qq">
          <a :href="`http://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes`" target="_blank" class="qq-icon center">
            <img style="height: 5vw" border="0" :src="require('@/assets/images/qq_icon.png')"/>
            &nbsp;点击咨询
          </a>
        </p>
        <p>
          <a target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=ohjTzw_JknWtn5bfOguD01OlP-BurI3W&jump_from=webapi" class="qq-icon center">
            <img style="height: 5vw" border="0" :src="require('@/assets/images/qq_icon.png')"/>
            &nbsp;加入群聊
          </a>
        </p>
      </div>
    </div>
  </div>
  <div class="goBack center" @touchend="gotoTop" @click="gotoTop">
    返回顶部&nbsp;<icon-font name="backtop" size="3vw"/>
  </div>
  <check-brower/>
  <Footer/>
</div>
</template>

<script>
import NavBar from '@/components/navBar'
import { IconFont } from 'tdesign-icons-vue-next'
import Footer from '@/components/footer'
import CheckBrower from '@/components/checkBrower'
export default {
  components: { NavBar, IconFont, Footer, CheckBrower },
  setup() {
    const trade_qq_numbers = ['1093064890', '1614485461', '1870976902', '2716533457', '1004658237']
    const service_qq_numbers = ['445253184', '1508128262']
    const phone_numbers = ['18028699442', '18028699443', '13802923543', '13802803453']

    const gotoTop = () => {
      window.scroll({top: 0})
    }

    return {
      trade_qq_numbers,
      service_qq_numbers,
      phone_numbers,
      gotoTop
    }
  }
}
</script>

<style lang="scss" scoped>
.page-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.section-wrap {
  margin-bottom: 3vw;
  flex-direction: column;
  width: 90vw;
  padding: 5vw;
  background-color: #f5f5f5;
  box-sizing: border-box;
  color: #444;
  h2 {
    font-weight: lighter;
  }
  .section-des {
    font-size: 3vw;
    font-weight: lighter;
    a.qq-icon {
      margin-right: 2vw;
      padding: 2vw;
      // border: 1px solid #f00;
      border-radius: 4px;
      background-color: #fff;
      color: #666;
    }
    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      line-height: .8;
      font-size: 3vw;
    }
    a {
      font-size: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }
}
.goBack {
  width: 100vw;
  height: 10vw;
  background-color: #333;
  color: #888;
  font-size: 3vw !important;
}
</style>