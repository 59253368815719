<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
  #app {
    width: 100%;
    height: 100%;
  }
</style>
