<template>
<div>
  <navBar>
    <template #content>
      <t-image :src="require('@/assets/images/logo-fill_300x300.png')" style="width: 5vw; height: 5vw"/>&nbsp;汇承信息
    </template>
  </navBar>
  <!-- HC-T使用步骤 -->
  <div class="useage-wrap">
    <div class="HC-T">
      <t-image :src="require('@/assets/images/HC-T-banner_750x750.png')" fit="fill" class="image"/>
      <div class="HC-T_des">
        <h1>HC-T</h1>
        <t-button
          class="center"
          theme="text"
          @click="showHCTPopup">调试步骤&nbsp;<icon-font name="chevron-right-circle"/></t-button>
      </div>
    </div>
    <!-- 弹出框 -->
    <t-popup v-model="HCTPopupVisible" placement="right">
      <div class="HCTPopup-wrap">
        <div class="header">
          <span>HC-T调试步骤</span>
          <icon-font name="close" size="7vw" @click="closeHCTPopup"/>
        </div>
        <div class="body">
          <h2 class="title"><icon-font name="caret-right-small" size="4vw"/>&nbsp;安装驱动</h2>
          <p class="des">将HC-T插入电脑，安装<b>HC-T驱动</b>，查看设备管理器出现虚拟串口说明安装成功</p>
          <h2 class="title"><icon-font name="caret-right-small" size="4vw"/>&nbsp;调试AT指令</h2>
          <p class="des">将插针款模块与HC-T连接（以HC-08为例），使用<b>电脑串口助手</b>，打开虚拟串口发送 'AT'，即返回 'OK' 表示调试成功</p>
          <h2 class="title"><icon-font name="caret-right-small" size="4vw"/>&nbsp;移动端通讯调试</h2>
          <p class="des">移动端下载<b>安卓串口助手</b>（仅安卓用户）或使用微信小程序 <b>“HC蓝牙BLE串口助手”</b>  与模块建立蓝牙连接，HC-T串口助手与移动端实现通讯。</p>
        </div>
        <div class="footer">
          <ul>
            <li v-for="download in popupDownloads" :key="download.name">
              <label style="font-size: 3vw">{{ download.name }}</label>
              <link-button :src="download.url">
                <icon-font name="download" size="3vw"/>
              </link-button>
            </li>
          </ul>
          <div class="wx">
            <t-image
              :src="require('@/assets/images/wx_miniProgram_HC_logo.jpg')"
              style="width: 30vw; height: 30vw"
              :lazy="true"/>
            <div class="right">
              <p>或 微信搜一搜</p>
              <p>“HC蓝牙BLE串口助手”</p>
            </div>
          </div>
          
        </div>
      </div>
    </t-popup>
  </div>
  
  <!-- 轮播 -->
  <t-swiper
    :interval="5000"
    :autoplay="true"
    :navigation="{ type: 'dots-bar', showSlideBtn: false }"
    @change="handleChange">
    <t-swiper-item
      v-for="(swiperItem, i) in swiperList"
      :key="i"
      class="swiper-item" style="background-color: #eee;">
      <t-image
        :src="swiperItem.image"
        fit="fill"
        style="width: 100%; height: 100%"
        :alt="swiperItem.url"
        :lazy="true"
        @touchstart.prevent="handleSwiperTouchStart"
        @touchend.prevent="handleSwiperTouchEnd"/>
    </t-swiper-item>
  </t-swiper>
 
  <!-- HC-12 -->
  <div class="home-banner-wrap">
    <h1>433M无线串口模块</h1>
    <p class="des">
      无线串口通信模块是新一代的多通道嵌入式无线数传模块。内部含有 MCU，用户无需对模块另外编程，各种透传模式只管收发串口数据即可，使用方便。
    </p>
    <div class="sub-des">
      <h2><icon-font name="check-circle-filled"/>&nbsp;支持一对多，低功耗</h2>
      <p>模块支持一发多收，同一个频段内的模块可以互相通讯。 通过切换不同的透传模式，可以设置模块低功耗。</p>
    </div>
    <div class="sub-des">
      <h2><icon-font name="check-circle-filled"/>&nbsp;超远距离，1公里通讯</h2>
      <p>不同透传模式下模块透传距离不同，空中波特率越低，传输距离越远，空旷环境实际测试距离达到1公里，满足用户各种需求。</p>
    </div>
    <t-image :src="require('@/assets/images/home_banner2.png')" :lazy="true"/>
    <div class="banner-footer">
      <t-button
        theme="primary"
        size="large"
        style="width: 60vw;"
        @touchend="gotoHC12"
        @click="gotoHC12">
        <span style="font-size: 4vw">了解更多</span>
      </t-button>
    </div>
  </div>
  <!-- HC-25 -->
  <div class="home-banner-wrap">
    <h1>WIFI串口模块</h1>
    <p class="des">
      HC–25 是汇承科技推出的全新的第五代嵌入式Simple–WiFi模块产品。基于UART接口的符合WiFi无线网络标准的嵌入式模块，内置TCP/IP协议栈，能够实现用户串口数据到无线网络之间的转换。通过UART–WiFi模块，传统的串口设备也能轻松接入无线网络。
    </p>
    <div class="sub-des">
      <h2><icon-font name="check-circle-filled"/>&nbsp;AT、网页、SmartConfig配置</h2>
      <p>支持三种不同形式的配置参数方式，极大的方便用户使用WIFI模块，参数保存即生效。</p>
    </div>
    <div class="sub-des">
      <h2><icon-font name="check-circle-filled"/>&nbsp;支持一对多、组网</h2>
      <p>IEEE802.11b/g/n无线标准、支持软AP，最多支持4个STA连接。</p>
    </div>
    <t-image :src="require('@/assets/images/home_banner3.png')" :lazy="true"/>
    <div class="banner-footer">
      <t-button
        theme="primary"
        size="large"
        style="width: 60vw;"
        @touchend="gotoHC25"
        @click="gotoHC25">
        <span style="font-size: 4vw">了解更多</span>
      </t-button>
    </div>
  </div>
  <check-brower/>
  <OnlineService/>
  <Footer/>
</div>
</template>

<script>
import { ref } from 'vue'
import NavBar from '@/components/navBar'
import OnlineService from '@/components/onlineService'
import { IconFont } from 'tdesign-icons-vue-next'
import Footer from '@/components/footer'
import { useRouter } from 'vue-router'
import LinkButton from '@/components/linkButton'
import CheckBrower from '@/components/checkBrower'
export default {
  components:  { NavBar, IconFont, OnlineService, Footer, LinkButton, CheckBrower },
  setup() {
    const router = useRouter()
    const HCTPopupVisible = ref(false)
    const swiperTouchStartScreenX = ref(0)
    const swiperList = [
      { image: require('@/assets/images/swiper_1_750x350.png'), url: '/goods/640e91920be12d0114404c92' },
      { image: require('@/assets/images/swiper_2_750x350.png'), url: '/goods/640e91920be12d0114404c99' },
      { image: require('@/assets/images/swiper_3_750x350.png'), url: '/goods/64101578c3c2fb11fcd14d3c' }
    ]
    const popupDownloads = [
      { name: 'HC-T驱动', url: 'http://www.hc01.com/downloads/CH341SER.rar' },
      { name: 'HC-T电脑串口助手', url: 'http://www.hc01.com/downloads/HC-T.rar' },
      { name: '安卓串口助手', url: 'http://www.hc01.com/downloads/HC_BlueTooth_v1.4.1.apk' }
    ]
    const gotoHC12 = () => {
      router.push('/goods/640e91920be12d0114404c95')
    }

    const gotoHC25 = () => {
      router.push('/goods/640e91920be12d0114404c99')
    }

    const showHCTPopup = () => {
      HCTPopupVisible.value = true
    }

    const closeHCTPopup = () => {
      HCTPopupVisible.value = false
    }

    const handleSwiperTouchStart = e => {
      swiperTouchStartScreenX.value = e.targetTouches[0].screenX
    }

    const handleSwiperTouchEnd = e => {
      if (e.changedTouches[0].screenX === swiperTouchStartScreenX.value) {
        router.push(e.target.alt)
      }
    }

    return {
      HCTPopupVisible,
      swiperList,
      popupDownloads,
      gotoHC12,
      gotoHC25,
      showHCTPopup,
      closeHCTPopup,
      handleSwiperTouchStart,
      handleSwiperTouchEnd,
    }
  }
}
</script>

<style lang="scss" scoped>
.t-swiper {
  border-radius: 0 !important;
}

.swiper-item {
  width: 100vw;
  height: auto;
}

.home-banner-wrap {
  padding: 10vw 0;
  background-color: #f5f5f5;
  h1 {
    padding: 10vw 0;
    font-size: 1.6rem;
    text-align: center;
  }
  .des {
    padding: 0 5vw;
    color: #666;
  }
  .sub-des {
    padding: 0 5vw;
    h2 {
      padding: 5vw 0;
      font-size: 1.2rem;
    }
    p {
      font-size: .9rem;
      color: #666;
    }
  }
  .banner-footer {
    padding: 20vw 0 10vw;
    text-align: center;
  }
}
.useage-wrap {
  padding: 10vw 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .HC-T {
    position: relative;
    margin-bottom: 10vw;
    width: 100vw;
    .HC-T_des {
      position: absolute;
      right: 5vw;
      bottom: 5vw;
      h1 {
        text-align: center;
        font-size: 10vw;
        font-weight: bolder;
      }
      p {
        font-size: 5vw;
        font-weight: lighter;
        color: #aaa;
      }
    }
  }
}
.HCTPopup-wrap {
  width: 80vw;
  min-height: 100vh;
  height: auto;
  background-color: #fff;
  overflow-y: scroll;
  border-radius: 5vw 0 0 0;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5vw;
    box-sizing: border-box;
    span {
      font-size: 4vw;
    }
  }
  .body {
    padding: 5vw;
    .title {
      display: flex;
      align-items: center;
      font-size: 4vw;
    }
    .des {
      font-size: 3vw;
      font-weight: lighter;
      color: #999;
      line-height: 1.5;
      b {
        font-size: 3vw;
        color: #666;
      }
    }
  }
  .footer {
    padding: 0 5vw;
    li {
      margin-bottom: 5vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .wx {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        p {
          font-size: 3vw;
          text-align: center;
        }
      }
    }
  }
}
</style>