<template>
<div class="result-wrap">
  <t-image :src="require('@/assets/images/search_empty.png')" style="width: 30vh; height: 30vh;"></t-image>
  <span>{{ title }}</span>
</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style lang="scss" scoped>
.result-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60vh;
  span {
    font-size: 6vw;
    color: #ddd;
  }
}
</style>