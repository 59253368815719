<template>
  <div class="page-wrap">
    <navBar :navBarTitle="good.name" back/>
    <div class="page-body">
      <t-swiper
        :interval="3000"
        :autoplay="true"
        :navigation="{ type: 'dots-bar', showSlideBtn: false }"
        @change="handleChange">
        <t-swiper-item v-for="image in good.images" :key="image" class="center">
          <t-image :src="image" :lazy="true" style="width: 100%; height: 100%"/>
        </t-swiper-item>
      </t-swiper>
      <collapse title="描述">
        <span style="font-size: 4vw">{{ good.des }}</span>
      </collapse>
      <collapse title="参数">
        <cell
          v-for="param in good.params"
          :key="param.key"
          :title="param.key"
          :value="param.value"
          size="4vw">
        </cell>
      </collapse>
      <collapse title="下载资料" v-if="good.downloads">
        <cell
          v-for="download in good.downloads"
          :key="download._id"
          :title="download.name"
          size="4vw">
          <template #value>
            <link-button 
              :src="download.url"
              size="8vw">
              <icon-font name="download" class="link-button-icon"></icon-font>
            </link-button>
          </template>
        </cell>
      </collapse>
    </div>
    <check-brower/>
    <OnlineService/>
    <Footer />
  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import axios from 'axios'
import { IconFont } from 'tdesign-icons-vue-next'
import NavBar from '@/components/navBar'
import OnlineService from '@/components/onlineService'
import Footer from '@/components/footer'
import Collapse from '@/components/collapse'
import Cell from '@/components/cell'
import LinkButton from '@/components/linkButton'
import BackTop from '@/components/backTop'
import CheckBrower from '@/components/checkBrower'
export default {
  components: {
    NavBar,
    OnlineService,
    Footer,
    Collapse,
    Cell,
    IconFont,
    LinkButton,
    BackTop,
    CheckBrower
  },
  setup() {
    const route = useRoute()
    const good = ref({})

    const fetchGood = async _id => {
      try {
        const { errCode, errMsg, good: result } = await axios(`/api/mobile/goods/${_id}`)
        if (errCode) throw new Error(errMsg)
        good.value = result
      } catch (err) {
        console.error(err)
      }
    }

    onMounted(async () => {
      await fetchGood(route.params._id)
    })

    return {
      good,
    }
  }
}
</script>

<style lang="scss" scoped>
.good-des {
  padding: 5vw;
  color: #666;
}
.link-button-icon {
  font-size: 4vw;
  line-height: 4vw;
}
</style>