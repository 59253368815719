<template>
<a
  :href="src"
  type="download"
  class="link-wrap"
  :style="{ 'width': size, 'height': size }">
  <slot>link</slot>
</a>
</template>

<script>
import { ref, watchEffect } from 'vue'
export default {
  props: {
    src: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '5vw'
    }
  },
  setup(props) {
    const halfSize = ref(0)

    watchEffect(() => {
      const [, sizeNumber, sizeUnit ] = /([\d\.]+)([^\d\.]+)/.exec(props.size)
      halfSize.value = `${sizeNumber / 2}${sizeUnit}`
    })

    return {
      halfSize
    }
  }
}
</script>

<style lang="scss" scoped>
.link-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vw;
  height: 5vw;
  color: #fff;
  background-color: #0052D9;
  border-radius: v-bind(halfSize);
  box-sizing: border-box;
  text-decoration: none !important;
}
</style>