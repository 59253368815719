<template>
  <div class="footer-wrap">
    <h1>联系我们</h1>
    <ul>
      <li class="footer-item">
        <icon-font name="call"/>&nbsp;020-84083341
      </li>
      <li class="footer-item">
        <icon-font name="mail"/>&nbsp;1004658237@qq.com
      </li>
      <li class="footer-item">
        <icon-font name="location"/>&nbsp;广州天河区科韵路建工路19号608
      </li>
      <li class="footer-item">
        <icon-font name="calendar"/>&nbsp;周一 至 周五、8:30 - 17:30
      </li>
      <li class="footer-item">
        &copy;&nbsp;2013 - {{ new Date().getFullYear() }} 广州汇承信息科技有限公司
      </li>
      <li class="footer-item">
        粤ICP备08127165号-3 
      </li>
    </ul>
  </div>
</template>

<script>
import { IconFont } from 'tdesign-icons-vue-next'
export default {
  components: { IconFont }
}
</script>

<style lang="scss" scoped>
.footer-wrap {
  padding: 5vw;
  background-color: #0052D9;
  box-sizing: border-box;
  color: #fff;
  h1 {
    font-size: 1.2rem;
  }
  .footer-item {
    padding: 2vw 0;
    font-size: .8rem;
  }
}
</style>