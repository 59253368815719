<template>
<t-popup v-model="popupVisible" placement="center" :closeOnOverlayClick="false">
  <div class="popup-wrap center">
    <div class="content">
      检测到PC端设备<br>
      是否访问PC端页面？
    </div>
    <div class="footer">
      <button class="ignore" @click.prevent="handleIgnoreClick">不在提示</button>
      <button class="confirm" @click.prevent="handleConfirmClick">跳转到PC端</button>
    </div>
  </div>
</t-popup>
</template>

<script>
import { useStore } from 'vuex'
import { ref, onMounted } from 'vue'
export default {
  setup() {
    const store = useStore()
    const popupVisible = ref(false)

    const checkBrower = () => {
      const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      popupVisible.value = !isMobile
    }

    const handleIgnoreClick = () => {
      store.commit('setIgnoreCheckBrower', true)
      popupVisible.value = false
    }

    const handleConfirmClick = () => {
      const _a = document.createElement('a')
      _a.href = 'http://www.hc01.com'
      _a.target = '_blank'
      _a.click()
    }

    onMounted(() => {
      !store.state.ignoreCheckBrower && checkBrower()
    })

    return {
      popupVisible,
      handleIgnoreClick,
      handleConfirmClick,
    }
  }
}
</script>

<style lang="scss" scoped>
.popup-wrap {
  flex-direction: column;
  padding: 20px;
  width: 30vw;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  .content {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: lighter;
    line-height: 2;
  }
  .footer {
    font-size: 0;
    button {
      padding: 5px 10px;
      font-size: 12px;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      &:first-child {
        margin-right: 5px;
      }
      &.ignore {
        background-color: transparent;
        border: 1px solid #e37318;
        color: #e37318;
      }
      &.confirm {
        background-color: #0052d9;
        color: #fff;
      }
    }
  }
}
</style>