<template>
<div class="downloads-wrap">
  <NavBar  navBarTitle="下载资料" back/>
  <t-list @scroll="handleListScroll">
    <search
      v-model="search"
      @change="handleSearchChange"
      @clear="handleSearchClear"/>
    
    <!-- TabBar -->
    <t-tabs
      default-value="0"
      class="tabs-wrap"
      @change="handleTabChange">
      <t-tab-panel
        value="0"
        label="全部">
      </t-tab-panel>
      <t-tab-panel
        v-for="category in downloadsCategories"
        :key="category._id"
        :value="category._id"
        :label="category.name">
      </t-tab-panel>
    </t-tabs>

    <!-- Downloads List -->
    <div v-if="downloadsList.length" class="list-wrap">
      <cell
        v-for="download in downloadsList"
        :key="download._id"
        :title="download.name"
        size="4vw">
        <template #value>
          <link-button 
            :src="download.url"
            size="8vw">
            <icon-font name="download" class="link-button-icon"></icon-font>
          </link-button>
        </template>
      </cell>
    </div>

    <result v-else-if="!loading" title="暂无下载资料"/>

    <div v-if="downloadsList.length" class="list-footer center">
      <t-loading
        v-if="loadingMore === 'more'"
        theme="dots"
      />
      <t-divider v-else-if="loadingMore === 'end'">&nbsp;END&nbsp;</t-divider>
    </div>
    
  </t-list>
  <check-brower/>
  <OnlineService/>
  <back-top/>
  <Footer/>
</div>
</template>

<script>
import { ref, onMounted } from 'vue'
import NavBar from '@/components/navBar'
import Cell from '@/components/cell'
import Search from '@/components/search'
import LinkButton from '@/components/linkButton'
import OnlineService from '@/components/onlineService'
import BackTop from '@/components/backTop'
import Footer from '@/components/footer'
import Result from '@/components/result'
import { IconFont } from 'tdesign-icons-vue-next'
import axios from 'axios'
import CheckBrower from '@/components/checkBrower'
export default {
  components: { NavBar, Cell, LinkButton, OnlineService, BackTop, Footer, IconFont, Search, Result, CheckBrower },
  setup() {
    const page = ref(1)
    const limit = 12
    const search = ref('')
    const category_id = ref('')
    const downloadsCategories = ref([])
    const downloadsList = ref([])
    const downloadsTotal = ref(0)
    const windowHeight = ref(0)
    const loading = ref(false)
    const loadingMore = ref('')
    
    const fetchDownloadsCategories = async () => {
      try {
        const { errCode, errMsg, categories } = await axios('/api/mobile/downloadCategories')
        if (errCode) throw new Error(errMsg)
        downloadsCategories.value = categories
      } catch (err) {
        console.error(err)
      }
    }

    const fetchDownloadsList = async isReset => {
      if (isReset) {
        page.value = 1
        loadingMore.value = ''
      }
      try {
        loading.value = true
        const { errCode, errMsg, downloads, total } = await axios('/api/mobile/downloads', {
          params: {
            page: page.value,
            limit,
            search: search.value,
            category_id: category_id.value
          }
        })
        if (errCode) throw new Error(errMsg)
        downloadsList.value = isReset ? downloads : downloadsList.value.concat(downloads)
        downloadsTotal.value = total
        loadingMore.value = downloadsTotal.value === downloadsList.value.length ? 'end' : ''
      } catch (err) {
        console.error(err)
      } finally {
        loading.value = false
      }
    }

    const handleSearchChange = async () => {
      await fetchDownloadsList(true)
    }

    const handleSearchClear = async () => {
      if (search.value.length) {
        search.value = ''
        await fetchDownloadsList(true)
      }
    }

    const handleTabChange = async value => {
      category_id.value = value === '0' ? '' : value
      await fetchDownloadsList(true)
    }

    const handleListScroll = () => {
      if (downloadsTotal.value === downloadsList.value.length || loadingMore.value === 'more') return
        const documentHeight = document.body.scrollHeight || document.documentElement.scrollHeight
        const scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
        if ((documentHeight - windowHeight.value) >= scrollTop) {
          page.value = page.value + 1
          loadingMore.value = 'more'
          setTimeout(async () => {
            await fetchDownloadsList()
          }, 1000)
        }
    }
    
    onMounted(async () => {
      await fetchDownloadsCategories()
      await fetchDownloadsList(true)
      windowHeight.value = window.innerHeight
    })
    
    return {
      loadingMore,
      search,
      downloadsList,
      downloadsCategories,
      handleTabChange,
      handleListScroll,
      handleSearchChange,
      handleSearchClear,
    }
  }
}
</script>

<style lang="scss" scoped>
.list-wrap {
  padding: 5vw;
  .link-button-icon {
    font-size: 4vw;
    line-height: 4vw;
  }
}
.list-footer {
  padding: 10vw 5vw;
}

</style>