import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

export default createStore({
  // plugins: [createPersistedState()],
  state() {
    return {
      ignoreCheckBrower: false
    }
  },
  mutations: {
    setIgnoreCheckBrower: (state, value) => {
      state.ignoreCheckBrower = value
    }
  }
})