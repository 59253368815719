import { createApp } from 'vue'
import App from './App.vue'
import TDesign from 'tdesign-mobile-vue'
import router from './router'
import store from './store'
import axios from 'axios'
import './style/global.scss'

/** 响应拦截器 */
axios.interceptors.response.use(res => {
  return res.data
})

const app = createApp(App)

app.use(router)
app.use(store)
app.use(TDesign)

app.mount('#app')
