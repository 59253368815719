<template>
<t-popup
  v-model="visible"
  placement="right"
  @closed="handleClose">
  <div class="filter-popup-content">
    <div class="header">
      <icon-font name="close" size="8vw" @click="$emit('update:modelValue', false)"/>
    </div>
    <div class="body">
      <div class="selected-box" v-if="selectedTags.length">
        <div class="selected-tag" v-for="tag in selectedTags" :key="tag">{{ tag }}</div>
      </div>
      <div
        class="filter-category-wrap"
        v-for="category in goodsCategories"
        :key="category._id">
        <div class="filter-category-title between-center">
          <span>{{ category.name }}</span>
          <icon-font :name="expandCategoryIds.includes(category._id) ? 'remove' : 'add'" size="5vw" @click="handleExpandChange(category._id)"/>
        </div>
        <div class="filter-category-tags" :class="{ 'expand': expandCategoryIds.includes(category._id) }">
          <div
            v-for="(tag, i) in category.tags"
            :key="i"
            class="tags-item"
            :class="{'selected': selectedTags.includes(tag)}"
            @click="handleTagClick(tag)">{{ tag }}</div>
        </div>
      </div>
    </div>
    <div class="footer center">
      <t-button theme="danger" size="small" @click="handleReset">重置</t-button>&nbsp;
      <t-button theme="primary" size="small" @click="handleConfirm">确定</t-button>
    </div>
  </div>
</t-popup>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { IconFont } from 'tdesign-icons-vue-next'
export default {
  components: { IconFont },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    tags: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const visible = ref(props.modelValue)
    const goodsCategories = ref([])
    const expandCategoryIds = ref([])
    const selectedTags = ref([])

    watchEffect(() => {
      visible.value = props.modelValue
      selectedTags.value = [...props.tags]
      goodsCategories.value = [...props.categories]
    })

    const handleExpandChange = _id => {
      const index = expandCategoryIds.value.indexOf(_id)
      if (index < 0) {
        expandCategoryIds.value.push(_id)
      } else {
        expandCategoryIds.value.splice(index, 1)
      }
    }

    const handleTagClick = tag => {
      const index = selectedTags.value.indexOf(tag)
      if (index < 0) {
        selectedTags.value.push(tag)
      } else {
        selectedTags.value.splice(index, 1)
      }
    }

    const handleClose = () => {
      emit('update:modelValue', false)
    }

    const handleReset = () => {
      selectedTags.value = []
      emit('reset')
    }

    const handleConfirm = () => {
      emit('confirm', selectedTags.value)
    }

    return {
      selectedTags,
      expandCategoryIds,
      visible,
      goodsCategories,
      handleClose,
      handleExpandChange,
      handleTagClick,
      handleReset,
      handleConfirm,
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-popup-content {
  width: 80vw;
  height: 100vh;
  padding: 5vw;
  background-color: #fff;
  box-sizing: border-box;
  .header {
    height: 5vh;
  }
  .body {
    height: calc(85vh - 5vw);
    overflow: scroll;
    .selected-box {
      padding: 5vw 5vw 0 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      .selected-tag {
        margin: 0 .2rem .2rem 0;
        padding: .2rem;
        font-size: .7rem;
        color: #002a7c;
        border: 1px solid #002a7c;
        border-radius: 4px;
      }
    }
    .filter-category-wrap {
      padding: 5vw 5vw 0;
      border-bottom: 1px solid #333;
      .filter-category-title {
        padding: 1rem 0;
        font-size: 4vw;
      }
      .filter-category-tags {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        height: auto;
        max-height: 0;
        overflow: hidden;
        transition: .5s all ease-in;
        &.expand {
          max-height: 25vh;
        }
        .tags-item {
          margin: 0 .4rem .4rem 0;
          padding: .3rem;
          font-size: .8rem;
          background-color: #eee;
          color: #666;
          border-radius: 4px;
          &.selected {
            background-color: #0052d9;
            color: #fff;
          }
        }
      }
    }
  }
  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10vh;
    background-color: #fff;
  }
}
</style>