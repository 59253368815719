<template>
<div class="cell-wrap" :style="{ 'fontSize': size }">
  <div class="cell-left">
    <slot name="title">{{ title }}</slot>
  </div>
  <div class="cell-right">
    <slot name="value">{{ value }}</slot>
  </div>
</div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '标题'
    },
    value: {
      type: String,
      default: '内容'
    },
    size: {
      type: String,
      default: '5vw'
    }
  }
}
</script>

<style lang="scss" scoped>
.cell-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vw 0;
  width: 100%;
  .cell-left {
    max-width: 82%;
    color: #333;
    font-size: inherit;
  }
  .cell-right {
    color: #aaa;
    font-size: inherit;
  }
}
</style>