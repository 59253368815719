<template>
  <div class="good-card-wrap" @click="gotoGoodDetail">
    <div class="good-card-image">
      <t-image :src="good.thumbnail[0]" :lazy="true"/>
    </div>
    <div class="good-card-des">{{ good.name }}</div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
export default {
  props: {
    good: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const router = useRouter()
    const gotoGoodDetail = () => {
      router.push(`/goods/${props.good._id}`)
    }

    return {
      gotoGoodDetail
    }
  }
}
</script>

<style lang="scss" scoped>
.good-card-wrap {
  margin: 2.5vw;
  padding: 5vw 0;
  width: 42.5vw;
  height: 67vw;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 3vw;
  .good-card-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42.5vw;
    height: 42.5vw;
  }
  .good-card-des {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 14.5vw;
    font-size: 4vw;
    font-weight: lighter;
  }
}
</style>