<template>
<div class="collapse-panel-wrap">
  <div class="header" @click="handleHeaderClick">
    <span>{{ title }}</span>
    <icon-font :name="expand ? 'chevron-up' : 'chevron-down'" size="8vw"/>
  </div>
  <Transition
    @before-enter="handleBeforeEnter"
    @enter="handleEnter"
    @before-leave="handleBeforeLeave"
    @leave="handleLeave"
    @after-leave="handleAfterLeave">
    <div v-show="expand" class="content">
      <div class="box">
        <slot>标题内容</slot>
      </div>
    </div>
  </Transition>
</div>
</template>

<script>
import { ref } from 'vue'
import { IconFont } from 'tdesign-icons-vue-next'
export default {
  components: { IconFont },
  props: {
    title: {
      type: String,
      default: '标题'
    }
  },
  setup(props) {
    const expand = ref(true)
    const handleHeaderClick = () => {
      expand.value = !expand.value
    }

    const handleBeforeEnter = el => {
      el.style.transition = '0.3s height ease-in-out'
      el.style.height = 0
    }

    const handleEnter = el => {
      el.style.height = el.scrollHeight !== 0
        ? `${el.scrollHeight}px`
        : 0
      el.style.overflow = 'hidden'
    }

    const handleBeforeLeave = el => {
      el.style.height = `${el.style.scrollHeight}px`
    }

    const handleLeave = el => {
      el.style.height = 0
      el.style.overflow = 'hidden'
    }

    const handleAfterLeave = el => {
      el.style.transition = ''
      el.style.height = ''
    }

    return {
      expand,
      handleHeaderClick,
      handleBeforeEnter,
      handleEnter,
      handleBeforeLeave,
      handleLeave,
      handleAfterLeave,
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse-panel-wrap {
  width: 100vw;
  padding: 0 5vw;
  box-sizing: border-box;
  background-color: #fff;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5vw 0;
    width: 100%;
    box-sizing: border-box;
    font-size: #000;
    border-bottom: 0.0001vw solid #aaa;
  }
  .content {
    box-sizing: border-box;
    .box {
      padding: 5vw 0;
    }
  }
}
</style>