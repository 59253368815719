<template>
<div>
  <t-sticky>
    <div class="navBar-wrap" :style="{background}">
      <div class="navBar-left">
        <slot name="left">
          <IconFont
            v-if="back"
            name="chevron-left"
            size="8vw"
            @click="handleBack"/>
        </slot>
      </div>
      <div class="navBar-content">
        <slot name="content">
          <span>{{ navBarTitle }}</span>
        </slot>
      </div>
      <div class="navBar-right">
        <slot name="right"></slot>
        <IconFont name="view-list" size="8vw" @click="openPopup"/>
      </div>
    </div>
  </t-sticky>
  <t-popup v-model="popupVisible" placement="top">
    <div class="popup-wrap">
      <div class="dropMenu-title">
        <t-image :src="require('@/assets/images/logo-fill_300x300.png')" style="width: 8vw; height: 8vw;"/>
        <icon-font name="close" size="8vw" @click="closePopup"/>
      </div>
      <div class="dropMenu-content">
        <ul class="dropMenu-list">
          <li class="dropMenu-list-item" data-index="/" @click="handleItemClick">
            <span>首页</span>
          </li>
          <li class="dropMenu-list-item" data-index="/goods" @click="handleItemClick">
            <span>商品</span>
          </li>
          <li class="dropMenu-list-item" data-index="/downloads" @click="handleItemClick">
            <span>下载资料</span>
          </li>
          <li class="dropMenu-list-item" data-index="/connectUs" @click="handleItemClick">
            <span>联系我们</span>
          </li>
        </ul>
      </div>
    </div>
  </t-popup>
</div>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { IconFont } from 'tdesign-icons-vue-next'
 export default {
  components: { IconFont },
  props: {
    back: {
      type: Boolean,
      default: false
    },
    navBarTitle: {
      type: String,
      default: '标题'
    },
    background: {
      type: String,
      default: '#fff'
    }
  },
  setup() {
    const router = useRouter()
    const popupVisible = ref(false)

    const openPopup = () => {
      popupVisible.value = true
    }

    const closePopup = () => {
      popupVisible.value = false
    }

    const handleBack = () => {
      router.back()
    }

    const handleItemClick = e => {
      popupVisible.value = false
      router.push(e.target.dataset.index)
    }

    return {
      /** Props */
      popupVisible,
      openPopup,
      closePopup,
      handleBack,
      handleItemClick,
    }
  }
 }
</script>

<style lang="scss" scoped>
.navBar-wrap {
  display: flex;
  padding: 1em 7vw;
  width: 100vw;
  box-sizing: border-box;
  z-index: 999 !important;
  .navBar-left,
  .navBar-content,
  .navBar-right {
    display: flex;
    width: 33.33vw;
    align-items: center;
    height: inherit;
  }
  .navBar-left {
    justify-content: flex-start;
  }
  .navBar-content {
    justify-content: center;
  }
  .navBar-right {
    justify-content: flex-end;
  }
}
.popup-wrap {
  width: 100vw;
  background-color: #fff;
  border-radius: 0 0 5vw 5vw;
}
.dropMenu-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 1em 7vw;
  box-sizing: border-box;
  border-bottom: 1px solid #eee;
}

.dropMenu-content {
  width: 100vw;
  padding: 0 3vw;
  box-sizing: border-box;
  ul {
    padding: 1rem;
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
      border-bottom: 1px solid #eee;
    }
  }
}
</style>