<template>
<div>
  <div
    class="service-wrap"
    @click.prevent="openPopup"
    @touchend.prevent="openPopup">
    <icon-font name="service"/>
  </div>
  <t-popup v-model="popupVisible" placement="bottom" to="body">
    <div class="popup-wrap">
      <p class="popup-item_call">
        <icon-font name="call" size="10vw"/>&nbsp;
        <a href="tel:020-84083341">020-84083341</a>
      </p>
      <p v-for="qq in trade_qq_numbers" :key="qq" class="popup-item_qq">
        <a :href="`http://wpa.qq.com/msgrd?v=3&uin=${qq}&site=qq&menu=yes`" target="_blank" class="qq-icon center">
          <icon-font name="logo-qq" size="5vw" style="color: #fff"/>
          &nbsp;点击咨询
        </a>
      </p>
    </div>
  </t-popup>
</div>

</template>

<script>
import { ref } from 'vue'
import { IconFont } from 'tdesign-icons-vue-next'
export default {
  components: { IconFont },
  setup() {
    const popupVisible = ref(false)
    const trade_qq_numbers = ['1614485461', '1870976902', '2716533457', '1004658237']
    const openPopup = () => {
      popupVisible.value = true
    }
    return {
      popupVisible,
      trade_qq_numbers,
      openPopup
    }
  }
}
</script>

<style lang="scss" scoped>
.service-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: fixed;
  right: 4vh;
  bottom: 10vh;
  width: 8vh;
  height: 8vh;
  border-radius: 4vh;
  font-size: 5vw;
  background-color: rgba(2,2,2,.8);
}
.popup-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5vw;
  width: 100vw;
  font-size: 8vw;
  background-color: #f5f5f5;
  box-sizing: border-box;
  border-radius: 5vw 5vw 0 0;
  p.popup-item_call {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  p.popup-item_qq {
    margin: 2vw 0 !important;
    line-height: 1vw;
  }
  a {
    text-decoration: none;
    color: #333;
    font-size: 8vw;
  }
  a.qq-icon {
    margin-right: 2vw;
    padding: 2vw;
    font-size: 4vw;
    font-weight: lighter;
    // border: 1px solid #f00;
    border-radius: 4px;
    background-color: #0052D9;
    color: #fff;
  }
}
</style>