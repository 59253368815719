<template>
<div class="search-wrap">
  <div class="inputer-wrap"
    @touchend="showSuffix"
    @click="showSuffix">
    <div class="pro center">
      <icon-font name="search" style="color: #aaa"/>
    </div>
    <form @submit.prevent="handleSubmit" action="javascript:return true" style="width: 100%">
    <input
      ref="Inputer"
      type="search"
      class="inputer"
      :class="{'focus': suffixVisible, 'clearable': search.length}"
      v-model="search"
      :placeholder="placeholder"
      @change.prevent="handleChange">
    </form>
    <div
      v-if="search.length"
      class="suffix center"
      @touchend.prevent.stop="handleClearTouch"
      @click.prevent.stop="handleClearTouch">
      <icon-font name="close" style="color: #aaa"/>
    </div>
  </div>
  <div
    v-if="suffixVisible"
    class="action"
    @touchend.prevent="handleCancelTouch"
    @click.prevent="handleCancelTouch">取消</div>
</div>
</template>

<script>
import { ref, watchEffect } from 'vue'
import { IconFont } from 'tdesign-icons-vue-next'
export default {
  emits: [ 'update:modelValue', 'change', 'clear' ],
  components: { IconFont },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入要查询的内容'
    }
  },
  setup(props, { emit }) {
    const Inputer = ref(null)
    const search = ref(props.modelValue)
    const suffixVisible = ref(false)

    watchEffect(() => {
      search.value = props.modelValue
    })

    const showSuffix = () => {
      suffixVisible.value = true
      Inputer.value.focus()
    }

    const handleSubmit = () => {
      if (search.value.length) {
        emit('update:modelValue', search.value)
        emit('change', search.value)
      }
      return true
    }

    const handleChange = () => {
      return false
    }

    const handleClearTouch = () => {
      search.value = ''
      emit('clear')
    }

    const handleCancelTouch = () => {
      suffixVisible.value = false
    }

    return {
      Inputer,
      search,
      suffixVisible,
      showSuffix,
      handleSubmit,
      handleChange,
      handleCancelTouch,
      handleClearTouch,
    }
  }
}
</script>

<style lang="scss" scoped>
.search-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5vw;
  // width: 100vw;
  width: 100%;
  height: 10vw;
  box-sizing: border-box;
  .inputer-wrap {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 10vw;
    background-color: #f5f5f5;
    border-radius: 5vw;
    box-sizing: border-box;
    .pro,
    .suffix {
      padding: 0 3vw;
      width: 10vw;
      height: 10vw;
      overflow: hidden;
      box-sizing: border-box;
    }
    input {
      padding: 1vw 0;
      width: 100%;
      height: 10vw;
      font-size: 4vw;
      box-sizing: border-box;
      &.focus.clearable {
        // width: 55vw;
        width: 100%;
      }
      &.focus {
        // width: 65vw;
        width: 100%;
      }
      &.clearable {
        // width: 70vw;
        // width: calc(100% - 20vw);
        width: 100%;
      }
      &::-webkit-search-cancel-button {
        display: none;
      }
      &::placeholder {
        font-size: 4vw;
        color: #aaa;
      }
    }
  }
  .action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15vw;
    height: 10vw;
    padding-left: 3vw;
    font-size: 4vw;
    color: #0052D9;
    box-sizing: border-box;
  }
}
</style>