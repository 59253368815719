<template>
  <div v-show="visible" class="backTop-wrap" @click="gotoBack">
    <icon-font name="backtop"></icon-font>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
import { IconFont } from 'tdesign-icons-vue-next'
export default {
  components: { IconFont },
  setup() {
    
    const visible = ref(false)
    
    const gotoBack = () => {
      window.scroll({top: 0})
    }

    const handleScroll = () => {
      const scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
      visible.value = scrollTop >= 100 ? true : false
    }

    onMounted(() => {
      window.addEventListener('scroll', handleScroll)
    })

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll)
    })

    return {
      visible,
      gotoBack,
    }
  }
}
</script>

<style lang="scss" scoped>
.backTop-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: fixed;
  right: 4vh;
  bottom: 20vh;
  width: 8vh;
  height: 8vh;
  border-radius: 4vh;
  font-size: 5vw;
  background-color: rgba(2,2,2,.8);
}
</style>