import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/Home'
import GoodsList from '@/pages/Goods/goodsList'
import GoodDetail from '@/pages/Goods/goodDetail'
import DownloadsList from '@/pages/Downloads/downloadsList'
import ConnectUs from '@/pages/ConnectUs/index'

export default createRouter({
  history: createWebHistory('/'),
  routes: [
    { path: '/home', component: Home },
    { path: '/goods', component: GoodsList },
    { path: '/goods/:_id', component: GoodDetail },
    { path: '/downloads', component: DownloadsList },
    { path: '/connectUs', component: ConnectUs },
    { path: '', redirect: '/home' },
  ]
})