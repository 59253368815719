<template>
<div class="page-wrap">
  <navBar  navBarTitle="商品列表" back/>
  <t-list @scroll="handleListScroll">
    <div class="filter-bar-wrap">
      <div class="filter-searcher">
        <Search
          v-model="search"
          placeholder="请输入关键字"
          @change="handleSearcherChange"
          @clear="handleSearcherClear"/>
      </div>
      
      <t-button
        style="width: 10vw; height: 10vw"
        shape="square"
        :theme="tags.length ? 'primary' : 'default'"
        @click="handleFilterClick">
        <icon-font name="filter" size="5vw" class="center"/>
      </t-button>
    </div>
    <div class="page-content">
      <t-grid v-if="goodsList.length" :column="2">
        <GoodCard
          v-for="good in goodsList"
          :key="good._id"
          :good="good"/>
      </t-grid>
      <Result v-else-if="!loading"/>
      
    </div>

    <div v-if="goodsList.length" class="list-footer center">
      <t-loading
        v-if="loadingMore === 'more'"
        theme="dots"
      />
      <t-divider v-else-if="loadingMore === 'end'">&nbsp;END&nbsp;</t-divider>
    </div>
  </t-list>
  <!-- 过滤弹出层 -->
  <filter-popup
    v-model="filterPopupVisible"
    :categories="goodsCategories"
    :tags="tags"
    ref="Filter"
    @reset="handleFilterReset"
    @confirm="handleFilterConfirm"/>
  <OnlineService/>
  <check-brower/>
  <back-top/>
  <Footer />
</div>
</template>

<script>
import { nextTick, ref, onMounted } from 'vue'
import { IconFont } from 'tdesign-icons-vue-next'
import NavBar from '@/components/navBar'
import OnlineService from '@/components/onlineService'
import BackTop from '@/components/backTop'
import Footer from '@/components/footer'
import GoodCard from '@/components/goodCard'
import FilterPopup from './components/filterPopup'
import Result from '@/components/result'
import Search from '@/components/search'
import axios from 'axios'
import CheckBrower from '@/components/checkBrower'
export default {
  components:  { NavBar, IconFont, OnlineService, BackTop, Footer, GoodCard, FilterPopup, Result, Search, CheckBrower },
  setup() {
    const Filter = ref(null)
    const tags = ref([])
    const search = ref('')
    const page = ref(1)
    const limit = 8
    const loading = ref('')
    const filterPopupVisible = ref(false)
    const goodsList = ref([])
    const goodsCategories = ref([])
    const goodsTotal = ref(0)
    const loadingMore = ref('')

    const fetchGoodsList = async isReset => {
      if (isReset) { page.value = 1 }
      try {
        loading.value = true
        const { errCode, errMsg, goods, total } = await axios('/api/mobile/goods', {
          params: {
            page: page.value,
            limit,
            tags: tags.value,
            search: search.value
          }
        })
        if (errCode) throw new Error(errMsg)
        goodsList.value = isReset ? goods : goodsList.value.concat(goods)
        goodsTotal.value = total
        loadingMore.value = goodsTotal.value === goodsList.value.length ? 'end' : ''
      } catch (err) {
        console.error(err)
      } finally {
        loading.value = false
      }
    }

    const fetchGoodCategories = async () => {
      try {
        const { errCode, errMsg, categories } = await axios('/api/mobile/goodCategories')
        if (errCode) throw new Error(errMsg)
        goodsCategories.value = categories
      } catch (err) {
        console.error(err)
      }
      
    }

    const handleSearcherChange = async () => {
      await fetchGoodsList(true)
    }

    const handleSearcherClear = async () => {
      if (search.value.length) {
        search.value = ''
        await fetchGoodsList(true)
      }
    }
    
    const handleFilterClick = () => {
      filterPopupVisible.value = true
    }

    const handleFilterReset = async () => {
      tags.value = []
      nextTick(() => {
        filterPopupVisible.value = false
      })
      await fetchGoodsList(true)
    }

    const handleFilterConfirm = async selectedTags => {
      tags.value = selectedTags
      nextTick(() => {
        filterPopupVisible.value = false
      })
      await fetchGoodsList(true)
    }

    const handleListScroll = () => {
      if (goodsTotal.value === goodsList.value.length || loadingMore.value === 'more') return
      const windowHeight = window.innerHeight
      const documentHeight = document.body.scrollHeight || document.documentElement.scrollHeight
      const scrollTop = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
      if ((documentHeight - windowHeight) === scrollTop) {
        page.value = page.value + 1
        loadingMore.value = 'more'
        setTimeout(async () => {
          await fetchGoodsList()
        }, 1000)
        
      }
    }

    onMounted(async () => {
      await fetchGoodCategories()
      await fetchGoodsList(true)
    })

    return {
      Filter,
      loadingMore,
      goodsList,
      goodsCategories,
      tags,
      search,
      filterPopupVisible,
      handleFilterClick,
      handleFilterReset,
      handleFilterConfirm,
      handleSearcherChange,
      handleSearcherClear,
      handleListScroll,
    }
  }
}
</script>

<style lang="scss" scoped>
.page-wrap {
  background-color: #f5f5f5;
}
.filter-bar-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vw 5vw;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
  .filter-searcher {
    width: 80vw;
  }
}
.page-content {
  padding: 2.5vw;
  width: 100vw;
  box-sizing: border-box;
}
.list-footer {
padding: 10vw 5vw;
}
</style>